
export default {
  components: {
    testimonialsSlider: () => import('@/components/scoped/testimonials/testimonialsSlider')
  },
  props: {
    page: {
      type: Object
    },
    reviews: {
      type: Array,
      default: []
    }
  }
}
