import { render, staticRenderFns } from "./testimonialsSection.vue?vue&type=template&id=1171076e&scoped=true&lang=html&"
import script from "./testimonialsSection.vue?vue&type=script&lang=js&"
export * from "./testimonialsSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1171076e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadingBlock: require('/var/www/app/components/base/headings/headingBlock.vue').default})
